<template>
    <div>
        <DisableInput />
        <div
            v-if="!store.state.game.currentGame.descriptionRead"
            class="riddle-description"
        >
            <img class="desc-image" :src="descImg" alt="" />
            <!-- <RiddleDescriptionSound :sound="sound" /> -->
            <p>
                {{ $t('Views.Game.Schloss.Kaminzimmer.Description') }}
            </p>
            <button
                @click="
                    store.commit({
                        type: 'updateDescriptionRead',
                        descriptionRead: true,
                    })
                "
            >
                {{ $t('Utilities.Riddles.DescriptionReadButton') }}
            </button>
        </div>
        <div
            v-show="store.state.game.currentGame.descriptionRead"
            class="riddle"
        >
            <img
                class="riddle-img"
                :src="soundboardDone ? kaminzimmer2 : kaminzimmer"
                alt="Kaminzimmer"
            />
            <svg
                class="riddle-svg"
                viewBox="0 0 2049 1536"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    v-if="!soundboardDone"
                    class="btn-svg"
                    @click="openPopupSoundboard"
                    d="M1058.5 470.5L1366.5 460L1372 924.5H1061L1058.5 470.5Z"
                />
                <path
                    v-if="soundboardDone"
                    class="btn-svg"
                    @click="openPopupHebel"
                    d="M1124 1092L1199.5 1087.5L1317.5 1092L1322.5 1335L1317.5 1376.5L1310.5 1384L1308 1412L1140 1407L1135.5 1382.5L1128.5 1372.5V1335H1119.5L1124 1092Z"
                />

                <path
                    v-if="unlocked"
                    class="btn-svg"
                    @click="solveRiddle"
                    d="M643.5 1064.5H869.5L886.5 1079L888.5 1205.5L874 1220H641.5L629.5 1202.5V1079L643.5 1064.5Z"
                />
            </svg>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, inject } from '@vue/runtime-core'
import { useStore } from 'vuex'
import DisableInput from '../../../components/Game/DisableInput.vue'

import * as kaminzimmer from '@/assets/schloss/kaminzimmer/kamin.jpeg'
// import * as kaminzimmer from '@/assets/schloss/kaminzimmer/kaminzimmer.png'
import * as kaminzimmer2 from '@/assets/schloss/kaminzimmer/kaminzimmer_feuer.png'
import * as soundboardPopupImage from '@/assets/schloss/kaminzimmer/soundboard.jpeg'
import * as popupGeheimfach from '@/assets/schloss/kaminzimmer/geheimfach.jpeg'
import * as popupHebel from '@/assets/schloss/kaminzimmer/hebel.jpeg'
import * as sound from '@/assets/kirche/altar/sound.mp3'
import * as descImg from '@/assets/schloss/background.jpg'
import * as clickSound from '@/assets/schloss/kaminzimmer/wooden.mp3'

// Pages
import * as pageEingang from '@/assets/schloss/pages/page_eingang.jpg'
import * as pageEiskeller from '@/assets/schloss/pages/page_eiskeller.jpg'
import * as pageGalerie from '@/assets/schloss/pages/page_galerie.jpg'
import * as pageTreppe from '@/assets/schloss/pages/page_treppe.jpg'
import * as pageKaminzimmer from '@/assets/schloss/pages/page_kaminzimmer.jpg'
import * as pageEingangEN from '@/assets/schloss/pages/page_eingang_en.jpg'
import * as pageEiskellerEN from '@/assets/schloss/pages/page_eiskeller_en.jpg'
import * as pageGalerieEN from '@/assets/schloss/pages/page_galerie_en.jpg'
import * as pageTreppeEN from '@/assets/schloss/pages/page_treppe_en.jpg'
import * as pageKaminzimmerEN from '@/assets/schloss/pages/page_kaminzimmer_en.jpg'
import { useEmitter } from '@/lib/useEmitter'
import RiddleDescriptionSound from '../../../components/Game/RiddleDescriptionSound.vue'

export default defineComponent({
    components: {
        DisableInput,
        RiddleDescriptionSound,
    },
    setup() {
        const store = useStore()
        const soundboardDone = ref(false)
        const showSoundboard = ref(false)
        const emitter = useEmitter()
        const unlocked = ref(false)

        const solveRiddle = () => emitter.emit('forceSolve')

        const openPopupSoundboard = () => {
            store.commit({
                type: 'addPopup',
                imgSrc: 'Soundboard',
                componentType: 'Soundboard',
            })
        }

        const openPopupGeheimfach = () => {
            store.commit('addPopup', {
                imgSrc: popupGeheimfach,
                componentType: 'Popup',
            })
        }
        const openPopupHebel = () => {
            store.commit('addPopup', {
                imgSrc: popupHebel,
                imgSrc: 'Kamin',
                componentType: 'Kamin',
            })
            soundboardDone.value = true
        }

        emitter.on(`soundboardDone`, () => {
            //console.log(`Done`)
            store.commit({ type: 'removePopups' })
            soundboardDone.value = true
        })

        emitter.on(`updateUnlocked`, (bool) => {
            unlocked.value = bool
            if (bool) {
                new Audio(clickSound).play()
                store.commit({ type: 'removePopups' })
                solveRiddle()
            }
        })
        const $app = inject('$app')
        const pageImageObject = {
            Eingang: {
                de: pageEingang,
                en: pageEingangEN
            },
            Eiskeller: {
                de: pageEiskeller,
                en: pageEiskellerEN
            },
            Galerie: {
                de: pageGalerie,
                en: pageGalerieEN
            },
            Treppe: {
                de: pageTreppe,
                en: pageTreppeEN
            },
            Kaminzimmer: {
                de: pageKaminzimmer,
                en: pageKaminzimmerEN
            }
        }
            
        store.commit({
            type: 'updateInventory',
            inventory: [
                { name: 'Tagebuchseite', img: pageImageObject.Eingang[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Eiskeller[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Galerie[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Treppe[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Kaminzimmer[$app.$i18n.locale] },
            ],
        })

        return {
            store,
            sound,
            descImg,
            kaminzimmer,
            kaminzimmer2,
            soundboardDone,
            unlocked,
            showSoundboard,
            solveRiddle,
            openPopupHebel,
            openPopupSoundboard,
            openPopupGeheimfach,
            soundboardPopupImage,
        }
    },
})
</script>

<style scoped>
.popup {
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2;
    height: 100vh;
    width: 100%;
}
.popup-close-button {
    position: absolute;
    cursor: pointer;
    width: 20px;
    text-align: center;
    top: 5px;
    right: 10px;
    z-index: 3;
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 7px;
    padding: 7px;
}
/* .riddle-img,
.riddle-svg {
    aspect-ratio: 3/4;
} */
.btn-svg {
    cursor: pointer;
}
</style>
