<template>
    <div>
        <DisableInput />
        <div
            v-if="!store.state.game.currentGame.descriptionRead"
            class="riddle-description"
        >
            <img class="desc-image" :src="descImg" alt="" />
            <!-- <RiddleDescriptionSound :sound="sound" /> -->
            <p>
                {{ $t(`Views.Game.Schloss.Adler.Description`) }}
            </p>
            <button
                @click="
                    store.commit({
                        type: 'updateDescriptionRead',
                        descriptionRead: true,
                    })
                "
            >
                {{ $t(`Utilities.Riddles.DescriptionReadButton`) }}
            </button>
        </div>
        <div
            v-show="store.state.game.currentGame.descriptionRead"
            class="riddle"
        >
            <img class="riddle-img" :src="adler" alt="Adler" />
            <!-- 
                Zum SVG:
                Bild laden, Objekte, welche geklickt werden sollen können mit Flächen "abgemalt" werden
                Hintergrund entfernen
            -->
            <svg
                class="riddle-svg"
                viewBox="0 0 1883 1412"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    class="btn-svg"
                    @click="openPopupTool"
                    d="M524.5 1052.5L550 1047.5H594.5L660.5 1070L702.5 1096.5L665 1190.5L922.5 1198.5L1275.5 1257.5L1284.5 1270L1288 1289L1278.5 1317L1071 1311.5L926 1301.5L643.5 1252L603.5 1344L550 1412H489L504.5 1339.5L512.5 1252L504.5 1219.5L512.5 1195.5L519.5 1070L524.5 1052.5Z"
                />
            </svg>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/runtime-core'
import { useStore } from 'vuex'

import * as adler from '@/assets/schloss/adler/adler.jpg'
import * as tool from '@/assets/schloss/adler/tool_adler.png'
import * as sound from '@/assets/kirche/altar/sound.mp3'

// Pages
import * as pageEingang from '@/assets/schloss/pages/page_eingang.jpg'
import * as pageEiskeller from '@/assets/schloss/pages/page_eiskeller.jpg'
import * as pageGalerie from '@/assets/schloss/pages/page_galerie.jpg'
import * as pageTreppe from '@/assets/schloss/pages/page_treppe.jpg'
import * as pageKaminzimmer from '@/assets/schloss/pages/page_kaminzimmer.jpg'
import * as descImg from '@/assets/schloss/background.jpg'
import DisableInput from '@/components/Game/DisableInput.vue'
import RiddleDescriptionSound from '../../../components/Game/RiddleDescriptionSound.vue'

export default defineComponent({
    setup() {
        const store = useStore()
        const openPopupTool = () => {
            store.commit({
                type: 'addPopup',
                imgSrc: tool,
                isForced: true,
                componentType: 'Popup',
            })
        }
        // Pages
        store.commit({
            type: 'updateInventory',
            inventory: [
                { name: 'Tagebuchseite', img: pageEingang },
                { name: 'Tagebuchseite', img: pageEiskeller },
                { name: 'Tagebuchseite', img: pageGalerie },
                { name: 'Tagebuchseite', img: pageTreppe },
                { name: 'Tagebuchseite', img: pageKaminzimmer },
            ],
        })
        return {
            store,
            adler,
            sound,
            descImg,
            openPopupTool,
        }
    },
    components: { DisableInput, RiddleDescriptionSound },
})
</script>

<style scoped></style>
