<template>
    <div class="popup-container">
        <div class="popup">
        <div class="popup-close-button" @click="closePopup">X</div>
        <img class="gif" :src="gif" alt="Gif, wie die Kugel zusammengesetzt wird." />
    </div>
    </div>
</template>

<script>
import { defineComponent, onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'

import * as gif from '@/assets/schloss/eingang/gif.gif'
import { useEmitter } from '../../../lib/useEmitter'

export default defineComponent({
    
    setup() {
        const store = useStore()
        const emitter = useEmitter()

        onMounted(() => {
            setTimeout(() => {
                // console.log(`Close`)
            }, 4000)
        })

        const closePopup = () => {
            emitter.emit(`closeKugelGif`)
        }

        return {gif, closePopup}
    },
})
</script>

<style scoped>
.popup-container {
    display: flex;
}
.popup-close-button {
    position: absolute;
    cursor: pointer;
    width: 20px;
    text-align: center;
    top: 10px;
    right: 10px;
    z-index: 3;
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 7px;
    padding: 7px;
}
.popup {
    position: relative;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    z-index: 2;
    height: 100vh;
    width: 100%;
    justify-content: center;
}
</style>
