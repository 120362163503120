<template>
    <div>
        <DisableInput />
        <div
            v-if="!store.state.game.currentGame.descriptionRead"
            class="riddle-description"
        >
        <img class="desc-image" :src="descImg" alt="" />
            <!-- <RiddleDescriptionSound :sound="sound" /> -->
            <p>
                {{ $t('Views.Game.Schloss.Treppe.Description') }}
            </p>
            <button
                @click="
                    store.commit({
                        type: 'updateDescriptionRead',
                        descriptionRead: true,
                    })
                "
            >
                {{ $t('Utilities.Riddles.DescriptionReadButton') }}
            </button>
        </div>
        <div
            v-show="store.state.game.currentGame.descriptionRead"
            class="riddle"
        >
            <img class="riddle-img" :src="backgroundImage" alt="Treppe" />
            <!--
                Zum SVG:
                Bild laden, Objekte, welche geklickt werden sollen können mit Flächen "abgemalt" werden
                Hintergrund entfernen
            -->
            <svg
                class="riddle-svg"
                viewBox="0 0 2049 1537"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    class="btn-svg"
                    @click="openDragAndDrop"
                    d="M563.5 321L578.5 317.5L614 311.5L640 317.5L661.5 325.5L654.5 375.5L636 386V407.5H683.5L703.5 510.5L695 513L683.5 500L679 518L673 569L679 577.5L654.5 609.5L656.5 669.5H575L583 618.5L561.5 569L549.5 522.5L542.5 500V483.5L526.5 497.5L519 490L546.5 411.5H583V397.5L598 382.5L578.5 379.5L575 368.5L563.5 321Z"
                />
                <path
                    class="btn-svg"
                    @click="openDragAndDrop"
                    d="M1652.5 306.5L1671.5 295L1696 292L1730 289L1759.5 295L1750 348L1742.5 357L1723 362.5V379.5L1730 374L1750 393.5H1775L1811 470.5L1802.5 480.5L1784.5 470.5L1777 510.5L1766.5 515V555L1746.5 590L1740.5 654L1750 665H1656.5L1665.5 651L1662 588L1646 583.5L1641.5 563.5L1632 524L1625 501.5V486L1605.5 498L1602 491.5L1632 389.5L1679 393.5L1684.5 365.5L1665.5 362.5L1656.5 357L1660 348L1652.5 306.5Z"
                />
                <path
                    v-if="solvable"
                    class="btn-svg"
                    @click="finishRiddle"
                    d="M728 971.5L1569 963L1566.5 992L1557.5 996.5L1556.5 1011L1547 1013L1540.5 1025.5L752 1027.5L746.5 1017L737 1014.5L735 1000L726 994L728 971.5Z"
                />
                <path
                    class="btn-svg"
                    @click="openSpruch"
                    d="M710.5 1151L1589.5 1156L1583.5 1173.5L1630 1180L1653 1196.5L1649 1221.5L676 1199.5L660.5 1190.5L720 1170L710.5 1151Z"
                />
            </svg>

            <WappenRiddle
                v-if="enableDragAndDrop"
                v-show="dragAndDrop"
                :show="dragAndDrop"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, inject } from '@vue/runtime-core'
import { useStore } from 'vuex'
import WappenRiddle from '@/components/Game/Schloss/WappenRiddle'
import DisableInput from '@/components/Game/DisableInput.vue'
// Normalerweise:
// Bitte beschreibende Namen für Bilder verwenden
import * as imgSrc from '@/assets/schloss/treppe/treppe_eingang.jpg'
import * as imgSrcSolve from '@/assets/schloss/treppe/treppe_eingang_open.jpg'
import * as popupSpruch from '@/assets/schloss/treppe/spruch.png'

import * as sound from '@/assets/kirche/altar/sound.mp3'
import * as descImg from '@/assets/schloss/background.jpg'

// Pages
import * as pageEingang from '@/assets/schloss/pages/page_eingang.jpg'
import * as pageEiskeller from '@/assets/schloss/pages/page_eiskeller.jpg'
import * as pageGalerie from '@/assets/schloss/pages/page_galerie.jpg'
import * as pageTreppe from '@/assets/schloss/pages/page_treppe.jpg'
import * as pageKaminzimmer from '@/assets/schloss/pages/page_kaminzimmer.jpg'
import * as pageEingangEN from '@/assets/schloss/pages/page_eingang_en.jpg'
import * as pageEiskellerEN from '@/assets/schloss/pages/page_eiskeller_en.jpg'
import * as pageGalerieEN from '@/assets/schloss/pages/page_galerie_en.jpg'
import * as pageTreppeEN from '@/assets/schloss/pages/page_treppe_en.jpg'
import * as pageKaminzimmerEN from '@/assets/schloss/pages/page_kaminzimmer_en.jpg'

import { useEmitter } from '../../../lib/useEmitter'
import RiddleDescriptionSound from '../../../components/Game/RiddleDescriptionSound.vue'

export default defineComponent({
    components: {
        WappenRiddle,
        DisableInput,
        RiddleDescriptionSound,
    },
    setup() {
        const store = useStore()
        const emitter = useEmitter()
        const enableDragAndDrop = ref(true)
        const solvable = ref(false)
        const dragAndDrop = ref(false)
        const backgroundImage = ref(imgSrc)

        const openDragAndDrop = () => {
            dragAndDrop.value = true
        }

        const closeDragAndDrop = () => {
            dragAndDrop.value = false
        }

        const finishRiddle = () => {
            emitter.emit('forceSolve')
        }

        const openSpruch = () => {
            store.commit({
                type: 'addPopup',
                imgSrc: popupSpruch,
                componentType: 'Popup',
            })
        }
        const $app = inject('$app')
        const pageImageObject = {
            Eingang: {
                de: pageEingang,
                en: pageEingangEN
            },
            Eiskeller: {
                de: pageEiskeller,
                en: pageEiskellerEN
            },
            Galerie: {
                de: pageGalerie,
                en: pageGalerieEN
            },
            Treppe: {
                de: pageTreppe,
                en: pageTreppeEN
            },
            Kaminzimmer: {
                de: pageKaminzimmer,
                en: pageKaminzimmerEN
            }
        }
            
        store.commit({
            type: 'updateInventory',
            inventory: [
                { name: 'Tagebuchseite', img: pageImageObject.Eingang[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Eiskeller[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Galerie[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Treppe[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Kaminzimmer[$app.$i18n.locale] },
            ],
        })

        emitter.on('closeDragAndDrop', closeDragAndDrop)
        emitter.on('dragAndDropSolved', () => {
            backgroundImage.value = imgSrcSolve
            closeDragAndDrop()
            solvable.value = true
        })

        return {
            store,
            sound,
            solvable,
            dragAndDrop,
            backgroundImage,
            enableDragAndDrop,
            finishRiddle,
            openDragAndDrop,
            openSpruch,descImg
        }
    },
})
</script>

<style scoped></style>
