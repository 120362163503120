<script setup>
import { onUpdated } from 'vue'
import * as wappenAdler from '@/assets/schloss/treppe/wappen_adler.png'
import * as wappenEnte from '@/assets/schloss/treppe/wappen_ente.png'
import * as wappenLoewe from '@/assets/schloss/treppe/wappen_loewe.png'
import * as wappenPferd from '@/assets/schloss/treppe/wappen_pferd.png'
import * as wappenTaube from '@/assets/schloss/treppe/wappen_taube.png'
import * as treppeLinks from '@/assets/schloss/treppe/treppe_links.jpg'
import * as treppeRechts from '@/assets/schloss/treppe/treppe_rechts.jpg'
import { useEmitter } from '../../../lib/useEmitter'

const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
})

const emitter = useEmitter()

const closePopup = () => {
    emitter.emit('closeDragAndDrop')
}

onUpdated(() => {
    const canvas = document.querySelector(`#canvas`)
    const ctx = canvas.getContext(`2d`)
    let canvasWidth = canvas.width
    let canvasHeight = canvas.height
    let offsetX = canvas.getBoundingClientRect().left
    let offsetY = canvas.getBoundingClientRect().top
    const movableImages = []
    const immovableImages = []

    const mouse = {
        x: undefined,
        y: undefined,
        relativeX: undefined,
        relativeY: undefined,
        setMouse: function (e) {
            this.x = parseInt(e.clientX - offsetX)
            this.y = parseInt(e.clientY - offsetY)
            this.relativeX = parseInt((e.clientX - offsetX) / canvasRatio.scale)
            this.relativeY = parseInt((e.clientY - offsetY) / canvasRatio.scale)
            return this
        },
        takeSnapshot: function () {
            this.snapshot = {
                x: this.x,
                y: this.y,
                relativeX: this.relativeX,
                relativeY: this.relativeY,
            }
        },
    }

    const canvasRatio = {
        originalWidth: 4000,
        originalHeight: 3000,
        scale: undefined,
    }

    const canvasState = {
        dragging: false,
    }

    const canvasGoals = {
        left: {
            finalObject: 2,
            currentObject: undefined,
            x: 614,
            y: 1445,
            width: 527 * 2,
            height: 651 * 2,
        },
        right: {
            finalObject: 4,
            currentObject: undefined,
            x: 3136,
            y: 1445,
            width: 527 * 2,
            height: 651 * 2,
        },
    }

    class MovableImage {
        constructor(id, image, x, y, customScale) {
            this.id = id
            this.image = image
            this.x = x
            this.y = y
            this.customScale = customScale
            this.width = image.width
            this.height = image.height
            this.zIndex = 1
            this.dragging = false
            this.snapped = false
        }
        inGoal() {
            if(canvasRatio.scale === undefined) return
            let centerX = this.x + (((this.width * this.customScale)*canvasRatio.scale) / 2)
            let centerY = this.y + (((this.height * this.customScale)*canvasRatio.scale) / 2)
            Object.values(canvasGoals).forEach((goalObject) => {
                // console.log(`--------------`)
                // prettier-ignore
                // console.log(`Test1: centerX < goalObject.x`)
                // console.log(`centerX: ${centerX}, goalObject.x: ${goalObject.x}`)
                if (centerX < goalObject.x) return
                // console.log(`Passed1`)
                // prettier-ignore
                // console.log(`Test2: centerX > goalObject.x + goalObject.width * canvasRatio.scale`)
                // console.log(`centerX: ${centerX}, goalObject.x + goalObject.width * canvasRatio.scale: ${goalObject.x + goalObject.width * canvasRatio.scale}, goalObject.x: ${goalObject.x} goalObject.width: ${goalObject.width} canvasRatio.scale: ${canvasRatio.scale}`)
                if (centerX > goalObject.x + goalObject.width * canvasRatio.scale) return
                // console.log(`Passed2`)
                // prettier-ignore
                // console.log(`Test3: centerY < goalObject.y`)
                // console.log(`centerX: ${centerY}, goalObject.x: ${goalObject.y}`)
                if (centerY < goalObject.y) return
                // console.log(`Passed3`)
                // prettier-ignore
                // console.log(`Test4: centerY > goalObject.y + goalObject.height * canvasRatio.scale`)
                // console.log(`centerY: ${centerY}, goalObject.height * canvasRatio.scale: ${goalObject.height * canvasRatio.scale}`)
                if (centerY > goalObject.y + goalObject.height * canvasRatio.scale) return
                // console.log(`Passed4`)
                // prettier-ignore
                // console.log(`Test5`)
                if(goalObject.currentObject !== undefined) return
                // console.log(`Passed5`)

                this.snapToGoal(goalObject)
            })
        }
        snapToGoal(goalObject) {
            // console.log(goalObject, this)
            goalObject.currentObject = this.id
            this.x = goalObject.x
            this.y = goalObject.y
        }
        update() {
            return this
        }
        draw() {
            ctx.drawImage(
                this.image,
                this.x * canvasRatio.scale,
                this.y * canvasRatio.scale,
                this.width * canvasRatio.scale * this.customScale,
                this.height * canvasRatio.scale * this.customScale
            )
            return this
        }
    }

    class ImmovableImage {
        constructor(image, x, y, customScale = 1) {
            this.image = image
            this.x = x
            this.y = y
            this.customScale = customScale
            this.width = image.width
            this.height = image.height
            this.size = Math.random()
        }
        update() {
            return this
        }
        draw() {
            ctx.drawImage(
                this.image,
                this.x * canvasRatio.scale,
                this.y * canvasRatio.scale,
                this.width * canvasRatio.scale * this.customScale,
                this.height * canvasRatio.scale * this.customScale
            )
            return this
        }
    }

    const canvasDimensions = () => {
        let dx = window.innerWidth * 0.9
        let dy = window.innerHeight * 0.9

        if (4 / dx > 3 / dy) {
            canvas.height = (dx / 4) * 3
            canvas.width = dx
            canvasRatio.scale = dx / canvasRatio.originalWidth
        } else {
            canvas.width = (dy / 3) * 4
            canvas.height = dy
            canvasRatio.scale = dy / canvasRatio.originalHeight
        }

        canvasWidth = canvas.width
        canvasHeight = canvas.height
        offsetX = canvas.getBoundingClientRect().left
        offsetY = canvas.getBoundingClientRect().top
    }

    function initMovableImages(id, source, x, y, customScale = 1) {
        let image = new Image()
        image.onload = () => {
            movableImages.push(new MovableImage(id, image, x, y, customScale))
        }
        image.src = source
    }

    function initImmovableImages(source, x, y, customScale = 1) {
        let image = new Image()
        image.onload = () => {
            immovableImages.push(new ImmovableImage(image, x, y, customScale))
        }
        image.src = source
    }

    function handleImmovableImages() {
        immovableImages.forEach((img) => img.update().draw())
    }

    function handleMovableImages() {
        let temp = movableImages.sort((a, b) => a.zIndex - b.zIndex)
        temp.forEach((img) => img.update().draw())
    }

    function draw() {
        handleImmovableImages()
        handleMovableImages()
    }

    const movableMouseDown = () => {
        let movableImagesUnderMouse = []
        // Filter all the Obj under mouse into array
        for (let i = 0; i < movableImages.length; i++) {
            /* prettier-ignore */
            if (
                mouse.relativeX > movableImages[i].x &&
                mouse.relativeX < movableImages[i].x + movableImages[i].width * movableImages[i].customScale &&
                mouse.relativeY > movableImages[i].y &&
                mouse.relativeY < movableImages[i].y + movableImages[i].height * movableImages[i].customScale
            ) {
                movableImagesUnderMouse.push(i)
            }
        }

        // Select the Obj with highest zIndex
        if (movableImagesUnderMouse.length > 0) {
            /* prettier-ignore */
            let currZMax = Math.max(...movableImages.map((imgObj) => imgObj.zIndex))
            /* prettier-ignore */
            let movableImagesUnderMouseZMax = Math.max(...movableImages.map((obj, index) => (movableImagesUnderMouse.includes(index) ? obj.zIndex : 0)))
            for (let i = 0; i < movableImagesUnderMouse.length; i++) {
                /* prettier-ignore */
                if(movableImages[movableImagesUnderMouse[i]].zIndex === movableImagesUnderMouseZMax) {
                    /* prettier-ignore */
                    canvasState.dragging = true
                    /* prettier-ignore */
                    movableImages[movableImagesUnderMouse[i]].dragging = true
                    /* prettier-ignore */
                    movableImages[movableImagesUnderMouse[i]].zIndex = currZMax + 1
                }
            }
        }
        mouse.takeSnapshot()
    }

    const movableMouseUp = () => {
        canvasState.dragging = false
        Object.values(canvasGoals).forEach(
            (goalObject) => (goalObject.currentObject = undefined)
        )
        // Noch nachdenken, wie gesnappt werden soll
        let temp = movableImages.sort((a, b) => b.zIndex - a.zIndex)
        for (let i = 0; i < temp.length; i++) {
            temp[i].inGoal()
            temp[i].dragging = false
        }
    }

    const movableMouseMove = () => {
        if (canvasState.dragging) {
            let distandX = mouse.relativeX - mouse.snapshot.relativeX
            let distandY = mouse.relativeY - mouse.snapshot.relativeY

            for (let i = 0; i < movableImages.length; i++) {
                if (movableImages[i].dragging) {
                    movableImages[i].x += distandX
                    movableImages[i].y += distandY
                }
            }

            mouse.takeSnapshot()
            draw()
        }
    }

    // window.addEventListener('resize', canvasDimensions) // Muss der als EventListener oder als Animate
    // Event Handling
    function handleMouseDown(e) {
        // Prevents
        e.preventDefault()
        e.stopPropagation()
        // Write on Mouse Object
        mouse.setMouse(e)
        // All handlers that use this event
        movableMouseDown()
    }

    function handleMouseUp(e) {
        // Prevents
        e.preventDefault()
        e.stopPropagation()
        // Write on Mouse Object
        mouse.setMouse(e)
        // All handlers that use this event
        movableMouseUp()
    }

    function handleMouseMove(e) {
        // Prevents
        e.preventDefault()
        e.stopPropagation()
        // Write on Mouse Object
        mouse.setMouse(e)
        // All handlers that use this event
        movableMouseMove()
    }

    // canvas.addEventListener('mousedown', handleMouseDown)
    // canvas.addEventListener('mouseup', handleMouseUp)
    // canvas.addEventListener('mousemove', handleMouseMove)
    
    canvas.addEventListener('pointerdown', handleMouseDown)
    canvas.addEventListener('pointerup', handleMouseUp)
    canvas.addEventListener('pointermove', handleMouseMove)

    initImmovableImages(treppeLinks, 0, 0, 2)
    initImmovableImages(treppeRechts, 2464, 0, 2)

    // For Randomized placing
    const movableImageData = [
        { id: 0, img: wappenEnte },
        { id: 1, img: wappenPferd },
        { id: 2, img: wappenAdler },
        { id: 3, img: wappenLoewe },
        { id: 4, img: wappenTaube },
        { id: 0, img: wappenEnte },
        { id: 1, img: wappenPferd },
        { id: 2, img: wappenAdler },
        { id: 3, img: wappenLoewe },
        { id: 4, img: wappenTaube },
    ]

    const movableImagePlaces = [
        { x: 350, y: 2400 },
        { x: 1050, y: 2400 },
        { x: 1750, y: 2400 },
        { x: 2450, y: 2400 },
        { x: 3150, y: 2400 },
        { x: 700, y: 2600 },
        { x: 1400, y: 2600 },
        { x: 2100, y: 2600 },
        { x: 2800, y: 2600 },
        { x: 3500, y: 2600 },
    ]

    movableImageData.forEach((imgData) => {
        let rndIndex = Math.floor(Math.random() * movableImagePlaces.length)
        initMovableImages(
            imgData.id,
            imgData.img,
            movableImagePlaces[rndIndex].x,
            movableImagePlaces[rndIndex].y,
            0.5
        )
        movableImagePlaces.splice(rndIndex, 1)
    })

    function animate() {
        if (!props.show) return
        ctx.clearRect(0, 0, canvasWidth, canvasHeight)

        if (
            canvasGoals.left.currentObject === canvasGoals.left.finalObject &&
            canvasGoals.right.currentObject === canvasGoals.right.finalObject
        ) {
            canvasDimensions() // Also EventListener oder als Animate
            draw()
            return emitter.emit('dragAndDropSolved')
        }

        canvasDimensions() // Also EventListener oder als Animate
        draw()

        requestAnimationFrame(animate)
    }
    animate()
})
</script>

<template>
    <div class="canvas-riddle-container">
        <canvas id="canvas" class="canvas"></canvas>
        <div class="popup-close-button" @click="closePopup">X</div>
    </div>
</template>

<style scoped>
.canvas {
    border: 1px solid #fff;
}
.canvas-riddle-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.9);
    z-index: 2;
    height: 100vh;
    width: 100%;
}
.popup-close-button {
    position: absolute;
    cursor: pointer;
    width: 20px;
    text-align: center;
    top: 10px;
    right: 10px;
    z-index: 3;
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 7px;
    padding: 7px;
}
</style>
