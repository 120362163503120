<template>
    <div>
        <div
            v-if="!store.state.game.currentGame.descriptionRead"
            class="riddle-description"
        >
            <img class="desc-image" :src="descImg" alt="" />
            <!-- <RiddleDescriptionSound :sound="sound" /> -->
            <p>
                {{ $t('Views.Game.Schloss.Galerie.Description') }}
            </p>
            <button
                @click="
                    store.commit({
                        type: 'updateDescriptionRead',
                        descriptionRead: true,
                    })
                "
            >
                {{ $t('Utilities.Riddles.DescriptionReadButton') }}
            </button>
        </div>
        <div
            v-show="store.state.game.currentGame.descriptionRead"
            class="riddle"
        >
            <img class="riddle-img" :src="imgSrc" alt="Galerie" />
            <svg
                class="riddle-svg"
                viewBox="0 0 1537 2049"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    class="btn-svg"
                    @click="openPopupBildLinks"
                    d="M1114.5 625L1159.5 587L1236 542L1226 763.5L1222.5 1024L1123.5 987L1117.5 857L1114.5 625Z"
                />
                <path
                    class="btn-svg"
                    @click="openPopupBildRechts"
                    d="M1256 539L1292 512.5L1408 459.5V1097L1322.5 1061.5L1262.5 1052.5L1256 539Z"
                />
            </svg>
        </div>
    </div>
</template>

<script>
import { defineComponent, inject } from '@vue/runtime-core'
import { useStore } from 'vuex'
// Normalerweise:
// Bitte beschreibende Namen für Bilder verwenden
import * as imgSrc from '@/assets/schloss/galerie/galerie.jpg'
import * as popupBildLinks from '@/assets/schloss/galerie/bild_links.jpg'
import * as popupBildRechts from '@/assets/schloss/galerie/bild_rechts.jpg'
import * as inventorySchachfigur from '@/assets/schloss/galerie/Schachfigur.png'

import * as sound from '@/assets/kirche/altar/sound.mp3'
import * as descImg from '@/assets/schloss/background.jpg'

// Pages
import * as pageEingang from '@/assets/schloss/pages/page_eingang.jpg'
import * as pageEiskeller from '@/assets/schloss/pages/page_eiskeller.jpg'
import * as pageGalerie from '@/assets/schloss/pages/page_galerie.jpg'
import * as pageTreppe from '@/assets/schloss/pages/page_treppe.jpg'
import * as pageKaminzimmer from '@/assets/schloss/pages/page_kaminzimmer.jpg'
import * as pageEingangEN from '@/assets/schloss/pages/page_eingang_en.jpg'
import * as pageEiskellerEN from '@/assets/schloss/pages/page_eiskeller_en.jpg'
import * as pageGalerieEN from '@/assets/schloss/pages/page_galerie_en.jpg'
import * as pageTreppeEN from '@/assets/schloss/pages/page_treppe_en.jpg'
import * as pageKaminzimmerEN from '@/assets/schloss/pages/page_kaminzimmer_en.jpg'
import RiddleDescriptionSound from '../../../components/Game/RiddleDescriptionSound.vue'

export default defineComponent({
    setup() {
        const store = useStore()
        const openPopupBildLinks = () => {
            store.commit({
                type: 'addPopup',
                imgSrc: popupBildLinks,
                componentType: 'Popup',
            })
        }
        const openPopupBildRechts = () => {
            store.commit({
                type: 'addPopup',
                imgSrc: popupBildRechts,
                componentType: 'Popup',
            })
        }

        const $app = inject('$app')
        const pageImageObject = {
            Eingang: {
                de: pageEingang,
                en: pageEingangEN
            },
            Eiskeller: {
                de: pageEiskeller,
                en: pageEiskellerEN
            },
            Galerie: {
                de: pageGalerie,
                en: pageGalerieEN
            },
            Treppe: {
                de: pageTreppe,
                en: pageTreppeEN
            },
            Kaminzimmer: {
                de: pageKaminzimmer,
                en: pageKaminzimmerEN
            }
        }
            
        store.commit({
            type: 'updateInventory',
            inventory: [
                { name: 'Tagebuchseite', img: pageImageObject.Eingang[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Eiskeller[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Galerie[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Treppe[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Kaminzimmer[$app.$i18n.locale] },
            ],
        })
        return {
            store,
            sound,
            imgSrc,
            openPopupBildLinks,
            openPopupBildRechts,
            descImg,
        }
    },
    components: { RiddleDescriptionSound },
})
</script>

<style scoped>
.riddle-img,
.riddle-svg {
    aspect-ratio: 3/4;
}
</style>
