<template>
    <div>
        <DisableInput />
        <div
            v-if="!store.state.game.currentGame.descriptionRead"
            class="riddle-description"
        >
            <img class="desc-image" :src="descImg" alt="" />
            <!-- <RiddleDescriptionSound :sound="sound" /> -->
            <p>
                {{ $t('Views.Game.Schloss.Eingang.Description') }}
            </p>
            <button
                @click="
                    store.commit({
                        type: 'updateDescriptionRead',
                        descriptionRead: true,
                    })
                "
            >
                {{ $t('Utilities.Riddles.DescriptionReadButton') }}
            </button>
        </div>
        <div
            v-show="store.state.game.currentGame.descriptionRead"
            class="riddle"
        >
            <img
                class="riddle-img"
                :src="useEingangOne ? eingangsBild : eingangsBild2"
                alt="Eingang"
            />
            <!--
                Zum SVG:
                Bild laden, Objekte, welche geklickt werden sollen können mit Flächen "abgemalt" werden
                Hintergrund entfernen
            -->
            <KugelGif v-if="enableGif" />

            <svg
                class="riddle-svg"
                viewBox="0 0 1474 1965"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    v-if="!enableSchiebetafel"
                    class="btn-svg"
                    @click="openPopupKugel"
                    d="M235 1655.5C242 1695 193.5 1741 158 1737C114 1727.5 84.5 1701 84.5 1651.5C84.5 1616.71 131.5 1665.5 158 1657C196.577 1644.63 218.5 1662.5 235 1655.5Z"
                />
                <path
                    v-if="enableSchiebetafel"
                    class="btn-svg"
                    @click="openSchiebeTafelPopup"
                    d="M60 981L85.5 980H195.5H271.5L326 981L329 982.5L337 989.5L344 1018L342 1046.5H324.5L314.5 1319L73.5 1317.5L65 1046.5H49L45.5 1015L51 997.5L60 981Z"
                />
                <path
                    v-if="enableSchiebetafel"
                    class="btn-svg"
                    @click="openPopupGanzeKugel"
                    d="M234.5 1658C239 1692.5 204.5 1733 161.5 1733C110.5 1727 86 1700.32 86 1660C86 1617.5 119.803 1587 161.5 1587C200 1585.5 234.5 1617.68 234.5 1658Z"
                />
            </svg>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, inject, onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useEmitter } from '@/lib/useEmitter'

// import SchiebeTafel from '@/components/Game/Schloss/SchiebeTafel.vue'
import DisableInput from '@/components/Game/DisableInput.vue'
import RiddleDescriptionSound from '@/components/Game/RiddleDescriptionSound.vue'
import KugelGif from '@/components/Game/Schloss/KugelGif.vue'
// Normalerweise:
// Bitte beschreibende Namen für Bilder verwenden
import * as eingangsBild from '@/assets/schloss/eingang/eingang.jpg'
import * as eingangsBild2 from '@/assets/schloss/eingang/eingang2.jpg'
import * as popupKugel from '@/assets/schloss/eingang/kugel.jpg'
import * as popupKugelGanz from '@/assets/schloss/eingang/kugel_ganz.jpg'
import * as inventoryKugel from '@/assets/schloss/eingang/halbe_kugel.png'

import * as sound from '@/assets/schloss/eingang/sound.mp3'
import * as descImg from '@/assets/schloss/background.jpg'

// Pages
import * as pageEingang from '@/assets/schloss/pages/page_eingang.jpg'
import * as pageEiskeller from '@/assets/schloss/pages/page_eiskeller.jpg'
import * as pageGalerie from '@/assets/schloss/pages/page_galerie.jpg'
import * as pageTreppe from '@/assets/schloss/pages/page_treppe.jpg'
import * as pageKaminzimmer from '@/assets/schloss/pages/page_kaminzimmer.jpg'
import * as pageEingangEN from '@/assets/schloss/pages/page_eingang_en.jpg'
import * as pageEiskellerEN from '@/assets/schloss/pages/page_eiskeller_en.jpg'
import * as pageGalerieEN from '@/assets/schloss/pages/page_galerie_en.jpg'
import * as pageTreppeEN from '@/assets/schloss/pages/page_treppe_en.jpg'
import * as pageKaminzimmerEN from '@/assets/schloss/pages/page_kaminzimmer_en.jpg'

export default defineComponent({
    components: {
        DisableInput,
        KugelGif,
        RiddleDescriptionSound,
    },
    setup() {
        const $app = inject('$app')
        const store = useStore()
        const emitter = useEmitter()
        const showSchiebetafel = ref(false)
        const enableSchiebetafel = ref(false)
        const enableGif = ref(false)
        const useEingangOne = ref(true)

        const pageImageObject = {
            Eingang: {
                de: pageEingang,
                en: pageEingangEN
            },
            Eiskeller: {
                de: pageEiskeller,
                en: pageEiskellerEN
            },
            Galerie: {
                de: pageGalerie,
                en: pageGalerieEN
            },
            Treppe: {
                de: pageTreppe,
                en: pageTreppeEN
            },
            Kaminzimmer: {
                de: pageKaminzimmer,
                en: pageKaminzimmerEN
            }
        }
            
        store.commit({
            type: 'updateInventory',
            inventory: [
                { name: 'Tagebuchseite', img: pageImageObject.Eingang[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Eiskeller[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Galerie[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Treppe[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Kaminzimmer[$app.$i18n.locale] },
            ],
        })

        const openSchiebeTafelPopup = () => {
            store.commit({
                type: 'addPopup',
                imgSrc: 'SchiebeTafel',
                componentType: 'SchiebeTafel',
            })
        }

        const openPopupKugel = () => {
            store.commit({
                type: 'updateInventory',
                inventory: [
                    {
                        name: 'Halbe Kugel',
                        img: inventoryKugel,
                        emitter: 'kugelClicked',
                    },
                ],
            })

            emitter.emit('kugelClickedUnten')
            store.commit({
                type: 'addPopup',
                imgSrc: popupKugel,
                componentType: 'Popup',
            })
        }

        const openPopupGanzeKugel = () => {
            store.commit({
                type: 'addPopup',
                imgSrc: popupKugelGanz,
                componentType: 'Popup',
            })
        }

        const solveRiddle = () => {
            emitter.emit('forceSolve')
        }
        const openSchiebeTafel = () => {
            showSchiebetafel.value = true
        }
        emitter.on('closeSchiebetafel', () => {
            showSchiebetafel.value = false
        })
        emitter.on(`closeKugelGif`, () => {
            enableGif.value = false
            useEingangOne.value = false
        })

        // Wird von Kugel Gif emitteed
        emitter.on('kugelClicked', () => {
            // Timeout muss sein, damit nicht vor dem öffnen des popups alle popups geschlossen werden
            setTimeout(() => store.commit({ type: 'removePopups' }), 1)
            enableGif.value = true

            enableSchiebetafel.value = true
            // Da Kugel nicht weiterhin im Inventar, musst Inventar resettet werden.
            store.dispatch({
                type: 'loadInventory',
                uuid: store.state.game.currentGame.uuid,
            })
            store.commit({
                type: 'updateInventory',
                inventory: [
                    { name: 'Tagebuchseite', img: pageEingang },
                    { name: 'Tagebuchseite', img: pageEiskeller },
                    { name: 'Tagebuchseite', img: pageGalerie },
                    { name: 'Tagebuchseite', img: pageTreppe },
                ],
            })
        })

        emitter.on('kugelClickedUnten', () => {})
        // Wird emitted von Schiebetafel
        emitter.on('doneSchiebetafel', () => {
            showSchiebetafel.value = false
            setTimeout(solveRiddle, 333)
        })

        return {
            store,
            showSchiebetafel,
            eingangsBild,
            eingangsBild2,
            useEingangOne,
            enableSchiebetafel,
            popupKugel,
            sound,
            enableGif,
            descImg,
            openPopupKugel,
            openSchiebeTafelPopup,
            openPopupGanzeKugel,
            openSchiebeTafel,
        }
    },
})
</script>

<style scoped>
.riddle-img,
.riddle-svg {
    aspect-ratio: 3/4;
}
</style>
